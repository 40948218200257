import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Main.scss';

function Main() {

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
          <img src="https://media.licdn.com/dms/image/v2/D5635AQGKlR_lS9P-Pg/profile-framedphoto-shrink_200_200/profile-framedphoto-shrink_200_200/0/1696158762531?e=1728086400&v=beta&t=u_pFTnYcHFg7d7TxhBaGO_OjlxhcjeZIXg6ekq2XHsw" alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">
            <a href="https://github.com/leegangmin" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/gangmin-lee/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
          <h1>Gangmin Lee</h1>
          <p>Full Stack Web Developer</p>

          <div className="mobile_social_icons">
            <a href="https://github.com/leegangmin" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/gangmin-lee/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;